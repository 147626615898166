<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap>
            <v-col cols="9">
                <!-- Page view -->
                <router-view :key="reloadPage" @agents-count="agentsCount"></router-view>
                <!-- end -->
            </v-col>
            <v-col cols="2">
               
            </v-col>
        </v-layout>
    </v-container>
</template>


<script>

  export default {
    name: 'Agents',
    data() {
      return {
        applyFilters: null,
        reloadPage: 0,
        countingAgents: 0
      }
    },

    methods:{
        filterSelected(val) {
            this.reloadPage += 1
            this.applyFilters = val
        },
        agentsCount(v){
          this.countingAgents = v
        }
    }
  }
</script>
